
import { useStore } from "@/store";
import { defineComponent } from "@vue/runtime-core";
import { mapGetters } from "vuex";
import { UploadStatus } from "./types";

export default defineComponent({
  name: "TaskProcessing",
  setup() {
    const store = useStore();
    return { store };
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("tasks", [
      "countTotal",
      "countSuccess",
      "countRunning",
      "getAllTask",
      "isShow",
    ]),
  },
  created() {
    setInterval(() => {
      this.store.dispatch("tasks/updateDoneTime");
    }, 60000);
  },
  methods: {
    close(): void {
      this.store.dispatch("tasks/setShow", {
        isShow: false,
      });
    },
    clear(): void {
      this.store.dispatch("tasks/clear");
    },
    isSuccess(status: UploadStatus): boolean {
      return status === UploadStatus.Success;
    },
    isUploading(status: UploadStatus): boolean {
      return status === UploadStatus.Uploading;
    },
    isFailed(status: UploadStatus): boolean {
      return status === UploadStatus.Failed;
    },
    isHavingTask(): boolean {
      return this.countTotal > 0;
    },
    getHeader(): string {
      if (this.countRunning > 0) {
        return this.$t("task.upload.file.uploading", {
          uploading: this.countRunning,
        });
      }
      if (this.countSuccess < this.countTotal) {
        return this.$t("task.upload.file.success", {
          success: this.countSuccess,
          total: this.countTotal,
        });
      }
      if (this.countTotal > 0) {
        return this.$t("task.upload.file.all.done", {
          uploaded: this.countTotal,
        });
      }
      return this.$t("task.upload.file.uploading.0");
    },
  },
  mounted() {
    window.onbeforeunload = () => {
      if (this.countRunning) return this.$t("message.close.confirmation");
    };
  },
});
