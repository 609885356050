import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "p-grid" }
const _hoisted_2 = { class: "p-col-4" }
const _hoisted_3 = {
  key: 1,
  src: "/assets/demo/images/avatar/profile.jpg",
  alt: "diamond-layout",
  class: "profile-image"
}
const _hoisted_4 = { class: "p-col-8" }
const _hoisted_5 = { class: "p-grid" }
const _hoisted_6 = { class: "p-col-4" }
const _hoisted_7 = { class: "p-text-bold" }
const _hoisted_8 = { class: "p-col-8" }
const _hoisted_9 = { class: "p-col-4" }
const _hoisted_10 = { class: "p-text-bold" }
const _hoisted_11 = { class: "p-col-8" }
const _hoisted_12 = { class: "p-col-4" }
const _hoisted_13 = { class: "p-text-bold" }
const _hoisted_14 = { class: "p-col-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    header: "My Profile",
    visible: _ctx.showDialog,
    "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => (_ctx.showDialog = $event)),
    modal: true,
    breakpoints: { '960px': '75vw' },
    style: { width: '50vw' }
  }, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode("div", _hoisted_2, [
          (_ctx.picture)
            ? (_openBlock(), _createBlock("img", {
                key: 0,
                src: _ctx.picture,
                alt: "diamond-layout",
                class: "profile-image"
              }, null, 8, ["src"]))
            : (_openBlock(), _createBlock("img", _hoisted_3))
        ]),
        _createVNode("div", _hoisted_4, [
          _createVNode("div", _hoisted_5, [
            _createVNode("div", _hoisted_6, [
              _createVNode("span", _hoisted_7, _toDisplayString(_ctx.$t("label.username")), 1)
            ]),
            _createVNode("div", _hoisted_8, [
              _createVNode("span", null, _toDisplayString(_ctx.username), 1)
            ]),
            _createVNode("div", _hoisted_9, [
              _createVNode("span", _hoisted_10, _toDisplayString(_ctx.$t("label.email")), 1)
            ]),
            _createVNode("div", _hoisted_11, [
              _createVNode("span", null, _toDisplayString(_ctx.email), 1)
            ]),
            _createVNode("div", _hoisted_12, [
              _createVNode("span", _hoisted_13, _toDisplayString(_ctx.$t("label.fullname")), 1)
            ]),
            _createVNode("div", _hoisted_14, [
              _createVNode("span", null, _toDisplayString(_ctx.name), 1)
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}