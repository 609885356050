
import { useStore } from "@/store";
import { mapGetters } from "vuex";
import { defineComponent } from "@vue/runtime-core";

/* eslint-disable */
export default defineComponent({
  name: "AppConfig",
  setup() {
    const store = useStore();
    return { store };
  },
  computed: {
    ...mapGetters("tasks", ["countTotal", "isShow"]),
  },
  methods: {
    isHavingTask(): boolean {
      return this.countTotal > 0;
    },
    onTaskButtonClick(event: any) {
      this.store.dispatch("tasks/switchShow");
      event.preventDefault();
    },
  },
});
