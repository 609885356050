<template>
  <Toast />
  <ConfirmDialog />
  <div
    :class="containerClass"
    :data-theme="colorScheme"
    @click="onDocumentClick($event)"
  >
    <div class="layout-content-wrapper">
      <AppTopBar
        :topbarNotificationMenuActive="topbarNotificationMenuActive"
        :topbarUserMenuActive="topbarUserMenuActive"
        @menu-button-click="onMenuButtonClick"
        @search-click="toggleSearch"
        @topbar-notification="onTopbarNotificationMenuButtonClick"
        @topbar-user-menu="onTopbarUserMenuButtonClick"
        @right-menubutton-click="onRightMenuButtonClick"
        @task-processing-click="onTaskProcessingClick"
        v-model:colorScheme="colorScheme"
      ></AppTopBar>

      <div class="layout-content">
        <div class="p-py-2 body">
          <!-- <div class="p-d-flex p-jc-between"></div> -->
          <router-view />
        </div>
      </div>
      <!-- <AppFooter /> -->
    </div>

    <AppMenu
      :model="menu"
      :layoutMode="layoutMode"
      :active="menuActive"
      :mobileMenuActive="staticMenuMobileActive"
      @menu-click="onMenuClick"
      @menuitem-click="onMenuItemClick"
      @root-menuitem-click="onRootMenuItemClick"
    ></AppMenu>

    <TaskProcessing></TaskProcessing>

    <AppConfig></AppConfig>

    <AppSearch
      :searchActive="searchActive"
      @search-click="onSearchClick"
      @search-hide="onSearchHide"
    />

    <div class="layout-mask modal-in"></div>
  </div>
</template>

<script>
/* eslint-disable */
import EventBus from "./event-bus";
import AppTopBar from "./AppTopbar";
import AppConfig from "./AppConfig";
import AppMenu from "./AppMenu";
import AppSearch from "./AppSearch";
import TaskProcessing from "../Task/TaskProcessing";
import { useStore } from "@/store";
import { getExperimentIcon } from "@/common/icon";
import { mapActions, mapState, mapGetters } from "vuex";
import { $http } from "@/main";

export default {
  setup() {
    const store = useStore();
    return { store };
  },
  created() {
    $http.interceptors.response.use(
      (response) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if (!error.response || error.response.status >= 500) {
          this.$toast.add({
            severity: "error",
            summary: this.$t("label.error"),
            detail: this.$t("error.unexpected"),
            life: 3000,
          });
        }
        return Promise.reject(error);
      }
    );
    const lastWorkspaceId = localStorage.workspace;
    this.loadPrivateWorkspace(this.userId).then((workspace) => {
      if (this.$route.path === "/") {
        if (lastWorkspaceId) {
          this.$router.push(`/workspace/${lastWorkspaceId}`);
        } else {
          this.$router.push(`/workspace/${workspace.id}`);
        }
      }
    });
  },
  data() {
    return {
      menuActive: false,
      layoutMode: "static",
      colorScheme: "light",
      menuTheme: "layout-sidebar-white",
      overlayMenuActive: false,
      staticMenuDesktopInactive: false,
      staticMenuMobileActive: false,
      menuClick: false,
      searchActive: false,
      searchClick: false,
      userMenuClick: false,
      topbarUserMenuActive: false,
      notificationMenuClick: false,
      topbarNotificationMenuActive: false,
      rightMenuClick: false,
      rightMenuActive: false,
      taskProcessingClick: false,
      configClick: false,
    };
  },
  computed: {
    ...mapState("authentication", ["userId", "activeTenant"]),
    ...mapState("workspace", ["privateWorkspace"]),
    ...mapGetters("authentication", ["isCurrentTenantAdmin"]),
    containerClass() {
      return [
        "layout-wrapper",
        {
          "layout-overlay": this.layoutMode === "overlay",
          "layout-static": this.layoutMode === "static",
          "layout-slim": this.layoutMode === "slim",
          "layout-sidebar-dim": this.colorScheme === "dim",
          "layout-sidebar-dark": this.colorScheme === "dark",
          "layout-overlay-active": this.overlayMenuActive,
          "layout-mobile-active": this.staticMenuMobileActive,
          "layout-static-inactive":
            this.staticMenuDesktopInactive && this.layoutMode === "static",
          "p-input-filled": this.$appState.inputStyle === "filled",
          "p-ripple-disabled": !this.$primevue.config.ripple,
        },
        this.colorScheme === "light" ? this.menuTheme : "",
      ];
    },
    showAddButton() {
      return !!this.$route.meta.addButtonRedirectUrl;
    },
    menu() {
      const workspaceId = this.$route.params.workspaceId
        ? this.$route.params.workspaceId
        : localStorage.getItem("workspace");
      const privateWorkspaceId = this.privateWorkspace
        ? this.privateWorkspace.id
        : localStorage.getItem("private_workspace");
      const menu = [
        {
          label: "",
          items: [
            {
              label: this.$t("menu.main.workspace"),
              icon: getExperimentIcon("main_ws"),
              command: async () => {
                this.$router.push(`/workspace/${privateWorkspaceId}`);
              },
            },
            {
              label: this.$t("menu.share.workspaces"),
              icon: getExperimentIcon("share_ws"),
              to: "/workspaces",
            },
            {
              separator: true,
            },
          ],
        },
      ];
      if (workspaceId) {
        menu.push(
          ...[
            {
              label: "",
              items: [
                {
                  label: this.$t("menu.home"),
                  icon: "icon-home",
                  to: `/workspace/${workspaceId}`,
                  exact: true,
                },
                {
                  label: this.$t("menu.datasource"),
                  icon: getExperimentIcon("datasource"),
                  to: `/workspace/${workspaceId}/datasource`,
                },
                {
                  label: this.$t("menu.experiment"),
                  icon: getExperimentIcon("automl"),
                  to: `/workspace/${workspaceId}/experiment`,
                },
              ],
            },
          ]
        );
      }

      if (this.isCurrentTenantAdmin) {
        menu.push({
          label: "",
          items: [
            {
              separator: true,
            },
            {
              label: this.$t("menu.billing.report"),
              icon: "icon-billing",
              to: '/billing/report',
              exact: true,
            },
            {
              label: this.$t("menu.billing.detail"),
              icon: "icon-billing",
              to: '/billing/detail',
              exact: true,
            }
          ]
        });
      }

      return menu;
    },
  },
  components: {
    AppTopBar,
    AppConfig,
    AppMenu,
    AppSearch,
    TaskProcessing,
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    },
  },
  methods: {
    ...mapActions("workspace", ["loadPrivateWorkspace"]),
    onDocumentClick() {
      if (!this.searchClick && this.searchActive) {
        this.onSearchHide();
      }

      if (!this.userMenuClick) {
        this.topbarUserMenuActive = false;
      }

      if (!this.notificationMenuClick) {
        this.topbarNotificationMenuActive = false;
      }

      if (!this.rightMenuClick) {
        this.rightMenuActive = false;
      }

      if (!this.menuClick) {
        if (this.isSlim()) {
          EventBus.emit("reset-active-index");
          this.menuActive = false;
        }

        if (this.overlayMenuActive || this.staticMenuMobileActive) {
          this.hideOverlayMenu();
        }

        this.unblockBodyScroll();
      }

      this.searchClick = false;
      this.configClick = false;
      this.userMenuClick = false;
      this.rightMenuClick = false;
      this.notificationMenuClick = false;
      this.menuClick = false;
    },
    onMenuClick() {
      this.menuClick = true;
    },
    onMenuButtonClick(event) {
      this.menuClick = true;
      this.topbarUserMenuActive = false;
      this.topbarNotificationMenuActive = false;
      this.rightMenuActive = false;

      if (this.isOverlay()) {
        this.overlayMenuActive = !this.overlayMenuActive;
      }

      if (this.isDesktop()) {
        this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
      } else {
        this.staticMenuMobileActive = !this.staticMenuMobileActive;
        if (this.staticMenuMobileActive) {
          this.blockBodyScroll();
        } else {
          this.unblockBodyScroll();
        }
      }

      event.preventDefault();
    },
    onMenuItemClick(event) {
      if (!event.item.items) {
        EventBus.emit("reset-active-index");
        this.hideOverlayMenu();
      }
      if (!event.item.items && this.isSlim()) {
        this.menuActive = false;
      }
    },
    onRootMenuItemClick() {
      this.menuActive = !this.menuActive;
    },
    onTopbarUserMenuButtonClick(event) {
      this.userMenuClick = true;
      this.topbarUserMenuActive = !this.topbarUserMenuActive;

      this.hideOverlayMenu();

      event.preventDefault();
    },
    onTopbarNotificationMenuButtonClick(event) {
      this.notificationMenuClick = true;
      this.topbarNotificationMenuActive = !this.topbarNotificationMenuActive;

      this.hideOverlayMenu();

      event.preventDefault();
    },
    toggleSearch() {
      this.searchActive = !this.searchActive;
      this.searchClick = true;
    },
    onSearchClick() {
      this.searchClick = true;
    },
    onSearchHide() {
      this.searchActive = false;
      this.searchClick = false;
    },
    onRightMenuClick() {
      this.rightMenuClick = true;
    },
    onRightMenuButtonClick(event) {
      this.rightMenuClick = true;
      this.rightMenuActive = !this.rightMenuActive;
      this.hideOverlayMenu();
      event.preventDefault();
    },
    onTaskProcessingClick(event) {
      this.store.dispatch("tasks/switchShow");
      this.hideOverlayMenu();
      event.preventDefault();
    },
    onConfigClick() {
      this.configClick = true;
    },
    hideOverlayMenu() {
      this.overlayMenuActive = false;
      this.staticMenuMobileActive = false;
      this.unblockBodyScroll();
    },
    blockBodyScroll() {
      if (document.body.classList) {
        document.body.classList.add("blocked-scroll");
      } else {
        document.body.className += " blocked-scroll";
      }
    },
    unblockBodyScroll() {
      if (document.body.classList) {
        document.body.classList.remove("blocked-scroll");
      } else {
        document.body.className = document.body.className.replace(
          new RegExp(
            "(^|\\b)" + "blocked-scroll".split(" ").join("|") + "(\\b|$)",
            "gi"
          ),
          " "
        );
      }
    },
    isSlim() {
      return this.layoutMode === "slim";
    },
    isOverlay() {
      return this.layoutMode === "overlay";
    },
    isDesktop() {
      return window.innerWidth > 991;
    },
    onClickAdd() {
      this.$router.push(this.$route.meta.addButtonRedirectUrl);
    },
  },
};
</script>

<style lang="scss">
@import "App.scss";
.body {
  height: calc(100vh - 56px);
  display: flex;
  flex-direction: column;
}
</style>
