
import { defineComponent } from "vue";
import { mapState } from "vuex";
export default defineComponent({
  data() {
    return {
      showDialog: this.visible,
    };
  },
  props: {
    visible: Boolean,
  },
  watch: {
    visible(newValue) {
      this.showDialog = newValue;
    },
    showDialog(newValue) {
      this.$emit("update:visible", newValue);
    },
  },
  emits: ["yes", "no", "update:visible", "hide"],
  computed: {
    ...mapState("authentication", ["username", "name", "email", "picture"]),
  },
});
