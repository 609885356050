<template>
  <div
    class="layout-topbar"
    :class="{
      'collapse-width': !is_collapse,
      'none-collapse-width': is_collapse,
    }"
  >
    <div class="topbar-left">
      <a tabindex="0" class="menu-button" @click="onMenuButtonClick">
        <i class="bx bx-menu"></i>
      </a>
      <span class="topbar-separator"></span>

      <div
        class="layout-breadcrumb viewname p-d-none p-d-lg-block"
        style="text-transform: uppercase"
      >
        <!-- <template v-if="$route.meta.breadcrumb">
          <span>{{ $route.meta.breadcrumb[0].label }}</span>
        </template> -->
        <Breadcrumb
          v-if="$route.meta.breadcrumb"
          :home="breadcrumbHome"
          :model="$route.meta.breadcrumb"
          :folderId="experimentDetail?.folderId || datasourceDetail?.folderId"
          :folderName="
            experimentDetail?.folderName || datasourceDetail?.folderName
          "
        />
      </div>
      <router-link to="/">
        <img
          id="logo-mobile"
          class="mobile-logo"
          src="/assets/layout/images/logo-horizontal-dark.svg"
        />
      </router-link>
    </div>

    <div class="topbar-right">
      <ul class="topbar-menu">
        <!-- <li class="search-item">
          <a tabindex="0" @click="onSearchClick">
            <i class="pi pi-search"></i>
          </a>
        </li> -->
        <!-- <li>
          <a v-if="$i18n.locale === 'en'" @click="change('ja')">
            <img
              src="/assets/demo/flags/flag_placeholder.png"
              :class="'flag flag-jp'"
              width="30"
            />
          </a>
          <a v-if="$i18n.locale === 'ja'" @click="change('en')">
            <img
              src="/assets/demo/flags/flag_placeholder.png"
              :class="'flag flag-gb'"
              width="30"
            />
          </a>
        </li> -->
        <li>
          <a v-if="colorScheme === 'light'" @click="changeColorScheme('dim')">
            <i class="bx bxs-moon"></i>
          </a>
          <a v-if="colorScheme === 'dim'" @click="changeColorScheme('light')">
            <i class="bx bx-sun"></i>
          </a>
        </li>
        <li
          class="language-item"
          :class="{ 'active-menuitem ': topbarNotificationMenuActive }"
        >
          <a href="#" tabindex="0" @click="onTopbarNotificationMenuButtonClick">
            <i class="bx bx-world"></i>
          </a>
          <ul class="language-menu fade-in-up" style="min-width: 100px">
            <li role="menuitem">
              <a href="#" tabindex="0" @click="change('en')">
                <span
                  class="flag-icon flag-icon-us flag-icon-squared p-mr-2"
                ></span>
                English
              </a>
            </li>
            <li role="menuitem">
              <a href="#" tabindex="1" @click="change('ja')">
                <span
                  class="flag-icon flag-icon-jp flag-icon-squared p-mr-2"
                ></span>
                日本語
              </a>
            </li>
          </ul>
        </li>

        <li
          class="profile-item"
          :class="{ 'active-menuitem fadeInDown': topbarUserMenuActive }"
        >
          <a href="#" @click="onTopbarUserMenuButtonClick">
            <img
              v-if="picture"
              :src="picture"
              alt="diamond-layout"
              class="profile-image"
            />
            <img
              v-else
              src="/assets/demo/images/avatar/profile.jpg"
              alt="diamond-layout"
              class="profile-image"
            />
            <div class="p-d-flex p-flex-column p-ai-start">
              <span class="profile-name">{{ name }}</span>
              <span class="profile-name p-text-secondary tenant-name">{{
                getOrganizationName(activeTenant)
              }}</span>
            </div>
          </a>
          <ul class="profile-menu fade-in-up">
            <li>
              <a href="#" @click="showProfileDialog = true">
                <i class="pi pi-user"></i>
                <span>{{ $t("label.profile") }}</span>
              </a>
            </li>
            <li>
              <a class="p-menuitem-link">
                <i class="p-menuitem-icon bx bxs-school"></i>
                <span class="p-menuitem-text">{{ $t("label.tenant") }}</span>
              </a>
              <ul class="p-submenu-list" role="menu">
                <li
                  class="p-menuitem"
                  v-for="(tenant, index) in tenants"
                  :key="tenant"
                >
                  <a class="p-menuitem-link" @click="onSelectTenant(tenant)">
                    <span class="p-menuitem-text">{{
                      `${index + 1}. ${getOrganizationName(tenant)}`
                    }}</span>
                    <i
                      class="p-ml-2 pi pi-check"
                      v-if="tenant == activeTenant"
                    ></i>
                  </a>
                </li>
              </ul>
            </li>
            <!-- <li>
              <a href="#">
                <i class="pi pi-cog"></i>
                <span>{{ $t("label.settings") }}</span>
              </a>
            </li> -->
            <!-- <li>
              <a href="#">
                <i class="pi pi-calendar"></i>
                <span>Calendar</span>
              </a>
            </li>
            <li>
              <a href="#">
                <i class="pi pi-inbox"></i>
                <span>Inbox</span>
              </a>
            </li> -->
            <li>
              <a href="#" @click="logout">
                <i class="pi pi-power-off"></i>
                <span>{{ $t("label.logout") }}</span>
              </a>
            </li>
          </ul>
        </li>

        <!-- <li class="right-sidebar-item">
          <a href="#" tabindex="0" @click="onRightMenuClick">
            <i class="pi pi-align-right"></i>
          </a>
        </li> -->
        <!-- <li class="right-sidebar-item">
          <a href="#" tabindex="0" @click="onTaskProcessingClick">
            <i class="pi pi-align-right"></i>
          </a>
        </li> -->
      </ul>
    </div>
  </div>
  <Profile v-model:visible="showProfileDialog" />
</template>

<script>
/* eslint-disable */
import { useStore } from "@/store";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import moment from "moment";
import { defineComponent } from "vue-demi";
import { primevueEn, primevueJp } from "@/plugin/primevue";
import Profile from "@/pages/Profile/Profile.vue";
import { WorkspaceType } from "../Workspace/types";

export default defineComponent({
  name: "AppTopbar",
  components: {
    Profile,
  },
  emits: [
    "menu-button-click",
    "search-click",
    "topbar-notification",
    "topbar-user-menu",
    "right-menubutton-click",
    "update:colorScheme",
    "task-processing-click",
  ],
  props: {
    topbarNotificationMenuActive: Boolean,
    topbarUserMenuActive: Boolean,
    colorScheme: String,
  },
  data() {
    return {
      items: [],
      d_colorScheme: this.colorScheme,
      logoColor: "dark",
      is_collapse: true,
      showProfileDialog: false,
    };
  },
  mounted() {
    document
      .querySelector('meta[name="theme-color"]')
      .setAttribute("content", "#f8f9fa");
    if (localStorage.colorScheme) {
      if (localStorage.colorScheme !== this.colorScheme) {
        this.changeColorScheme(localStorage.colorScheme);
      }
    }
    if (localStorage.language) {
      if (localStorage.language !== this.$i18n.lang) {
        this.change(localStorage.language);
      }
    }
  },
  unmounted() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  },
  setup() {
    const store = useStore();
    return { store };
  },
  methods: {
    ...mapMutations("application", ["SET_THEME"]),
    ...mapActions("authentication", ["changeTenant"]),
    ...mapActions("workspace", ["loadWorkspaceById"]),
    onMenuButtonClick(event) {
      this.is_collapse = !this.is_collapse;
      this.$emit("menu-button-click", event);
    },
    onSearchClick(event) {
      this.$emit("search-click", event);
    },
    onTopbarNotificationMenuButtonClick(event) {
      this.$emit("topbar-notification", event);
    },
    onTopbarUserMenuButtonClick(event) {
      this.$emit("topbar-user-menu", event);
    },
    onRightMenuClick(event) {
      this.$emit("right-menubutton-click", event);
    },
    onTaskProcessingClick(event) {
      this.$emit("task-processing-click", event);
    },
    change(lang) {
      try {
        this.$i18n.locale = lang;
        moment.locale(lang);
        localStorage.setItem("language", lang);
        this.store.dispatch("tasks/updateDoneTime");
        this.store.dispatch("authentication/changeFullname");
        if (lang === "ja") {
          this.$primevue.config.locale = primevueJp;
        } else {
          this.$primevue.config.locale = primevueEn;
        }
      } catch (err) {
        console.error(err);
      }
    },
    logout() {
      this.store.dispatch("authentication/logout");
    },
    getThemeColor(scheme) {
      switch (scheme) {
        case "light":
          return "#f8f9fa";
        case "dim":
          return "#17212f";
        default:
          return "#f8f9fa";
      }
    },
    changeColorScheme(scheme) {
      this.$emit("update:colorScheme", scheme);
      localStorage.setItem("colorScheme", scheme);
      this.SET_THEME(scheme);
      this.changeStyleSheetUrl("layout-css", "layout-" + scheme + ".css", 1);
      this.changeStyleSheetUrl("theme-css", "theme-" + scheme + ".css", 1);
      this.changeLogo();
      document
        .querySelector('meta[name="theme-color"]')
        .setAttribute("content", this.getThemeColor(scheme));
    },
    changeStyleSheetUrl(id, value, from) {
      const element = document.getElementById(id);
      const urlTokens = element.getAttribute("href").split("/");

      if (from === 1) {
        // which function invoked this function
        urlTokens[urlTokens.length - 1] = value;
      } else if (from === 2) {
        // which function invoked this function
        if (value !== null) {
          urlTokens[urlTokens.length - 2] = value;
        }
      } else if (from === 3) {
        // which function invoked this function
        urlTokens[urlTokens.length - 2] = value;
      }

      const newURL = urlTokens.join("/");

      this.replaceLink(element, newURL);
    },

    changeLogo() {
      const appLogoLink = document.getElementById("app-logo");
      const mobileLogoLink = document.getElementById("logo-mobile");
      const invoiceLogoLink = document.getElementById("invoice-logo");
      const footerLogoLink = document.getElementById("footer-logo");
      const logoUrl = `/assets/layout/images/logo-${
        this.colorScheme === "light" ? "white" : "dark"
      }.svg`;

      const logoIconUrl = `/assets/layout/images/logo-horizontal-${
        this.colorScheme === "light" ? "white" : "dark"
      }.svg`;

      if (appLogoLink) {
        appLogoLink.src = `/assets/layout/images/logo-${
          this.colorScheme === "light" ? "white" : "dark"
        }.svg`;
      }

      if (mobileLogoLink) {
        mobileLogoLink.src = logoIconUrl;
      }

      if (invoiceLogoLink) {
        invoiceLogoLink.src = logoUrl;
      }

      if (footerLogoLink) {
        footerLogoLink.src = logoUrl;
      }
    },
    replaceLink(linkElement, href) {
      if (this.isIE()) {
        linkElement.setAttribute("href", href);
      } else {
        const id = linkElement.getAttribute("id");
        const cloneLinkElement = linkElement.cloneNode(true);

        cloneLinkElement.setAttribute("href", href);
        cloneLinkElement.setAttribute("id", id + "-clone");

        linkElement.parentNode.insertBefore(
          cloneLinkElement,
          linkElement.nextSibling
        );

        cloneLinkElement.addEventListener("load", () => {
          linkElement.remove();
          cloneLinkElement.setAttribute("id", id);
        });
      }
    },
    isIE() {
      return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
    },
    onSelectTenant(tenant) {
      localStorage.removeItem("workspace");
      this.changeTenant(tenant);

      // Redirect page to first path of current URL
      const firstPath = window.location.pathname.split("/")[1];
      location.replace(`/${firstPath}`);
    },
  },
  computed: {
    ...mapState("authentication", [
      "name",
      "picture",
      "tenants",
      "activeTenant",
    ]),
    ...mapState("workspace", ["workspaceDetail"]),
    ...mapState("experiments", ["experimentDetail"]),
    ...mapState("datasources", ["datasourceDetail"]),
    ...mapGetters("identities", ["getOrganizationName"]),
    breadcrumbHome() {
      if (this.$route.name?.startsWith("Billing")) {
        return {
          icon: "pi pi-home",
          to: `/workspaces`,
          label: this.$t("menu.home"),
        };
      }

      let wsName = "";
      let workspaceId = this.$route.params.workspaceId;
      if (workspaceId && workspaceId !== this.workspaceDetail?.id) {
        this.loadWorkspaceById(workspaceId);
      }

      if (this.workspaceDetail?.type === WorkspaceType.Shared) {
        wsName = this.workspaceDetail.name;
      } else {
        wsName = this.$t("workspace.title", { name: this.name });
      }
      return {
        icon: "pi pi-home",
        to: `/workspace/${this.$route.params.workspaceId}`,
        label: wsName,
      };
    },
  },
});
</script>

<style lang="scss" scoped>
.bx-menu:before {
  content: "\eac1" !important;
}
.language-menu .fade-in-up {
  min-width: 100px;
}

@media screen and (min-width: 992px) {
  .collapse-width {
    z-index: 999;
    position: fixed;
    right: 0;
    width: 100vw;
  }

  .none-collapse-width {
    z-index: 999;
    position: fixed;
    right: 0;
    width: 100%;
    padding-left: 18rem;
  }
}
.p-breadcrumb {
  background: none;
  border: none;
  border-radius: none;
}
.p-submenu-list {
  list-style: none;
  padding-left: 1rem;
}

::v-deep(.profile-name) {
  line-height: initial;
}

.tenant-name {
  opacity: 0.8;
}
</style>
